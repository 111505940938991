import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting started`}</h1>
    <p>{`Authorising payments for a patient with Medipass requires three steps:`}</p>
    <ol>
      <li parentName="ol">{`Setup the Payment Authority SDK`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/sdk/payment-authority/getting-started/obtaining-token/"
        }}>{`Obtaining a token`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/sdk/payment-authority/getting-started/authorizing-payment/"
        }}>{`Render the authorise payment frame`}</a></li>
    </ol>
    <h2 {...{
      "id": "setup-the-payment-authority-sdk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setup-the-payment-authority-sdk",
        "aria-label": "setup the payment authority sdk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup the Payment Authority SDK`}</h2>
    <h3 {...{
      "id": "requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#requirements",
        "aria-label": "requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requirements`}</h3>
    <p>{`The Payment Authority SDK requires a valid `}<strong parentName="p">{`API Key`}</strong>{` & `}<strong parentName="p">{`App ID`}</strong>{`. It is also recommended that you set the `}<strong parentName="p">{`Environment`}</strong>{` to staging when testing the SDK in development.`}</p>
    <h4 {...{
      "id": "environment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#environment",
        "aria-label": "environment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Environment`}</h4>
    <p>{`Two environments are available:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`stg`}</strong>{`: our staging / testing environment. This represents a production like environment but uses test account details and stubs to simulate responses from funders. By default, some external notifications like SMS are normally disabled but can be enabled via help desk request.`}</li>
      <li parentName="ul"><strong parentName="li">{`prod`}</strong>{`: our production environment.`}</li>
    </ul>
    <h4 {...{
      "id": "api-keys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#api-keys",
        "aria-label": "api keys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`API keys`}</h4>
    <p>{`An API key grants access to a business and all related providers and resources including practices, staff members, claims, payments and patients data. API keys are unique to a business account in Medipass. For example, an allied healthcare business “Always Healthy Pty Ltd” with four practice locations and 20 providers can use a single API key to create claims and access remittance details across the business.`}</p>
    <h5 {...{
      "id": "how-do-i-create-an-api-key",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#how-do-i-create-an-api-key",
        "aria-label": "how do i create an api key permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I create an API key?`}</h5>
    <p>{`The API key can only be created by the business owner using the Medipass portal.`}</p>
    <ol>
      <li parentName="ol">{`Login to the Medipass portal: `}<a parentName="li" {...{
          "href": "https://connect.medipass.io"
        }}>{`https://connect.medipass.io`}</a></li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Medipass account`}</strong>{` in the bottom left hand corner`}</li>
      <li parentName="ol">{`In the `}<strong parentName="li">{`API Keys`}</strong>{` panel, select `}<strong parentName="li">{`Generate API Key`}</strong></li>
      <li parentName="ol">{`The API Key will be displayed`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`As the API key is specific to a business and can be used to access sensitive resources, it should be stored securely in your system - preferably via strong encryption with associated key management.`}</p>
    </blockquote>
    <h4 {...{
      "id": "app-ids",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#app-ids",
        "aria-label": "app ids permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`App IDs`}</h4>
    <p>{`An App ID allows us to identify who you are, so Medipass can debug problems & pinpoint where issues are arising from. You can choose to specify a single application name or generate different App IDs for each version of your software. Your App ID must be registered with Medipass. `}<strong parentName="p">{`Contact Medipass via `}<a parentName="strong" {...{
          "href": "mailto:support@medipass.com.au"
        }}>{`support@medipass.com.au`}</a>{` or via chat to apply for your App ID.`}</strong></p>
    <h4 {...{
      "id": "domain-whitelist",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#domain-whitelist",
        "aria-label": "domain whitelist permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Domain whitelist`}</h4>
    <p>{`For security reasons, Medipass will also need to whitelist your origin domain names. Please `}<strong parentName="p">{`contact `}<a parentName="strong" {...{
          "href": "mailto:support@medipass.com.au"
        }}>{`support@medipass.com.au`}</a></strong>{` so we can add your domains to our content security policy.`}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h3>
    <p>{`The Payment Authority SDK can be installed via NPM/Yarn or downloaded via `}<a parentName="p" {...{
        "href": "https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js"
      }}>{`unpkg.com`}</a>{`. If you plan to use the SDK as a JavaScript module, then install the NPM/Yarn package. If you plan to use the SDK with a `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tag or other URL direction method, then use the unpkg.com version.`}</p>
    <p><strong parentName="p">{`Install via NPM/Yarn`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`npm install @medipass/partner-sdk
// or, with yarn:
yarn add @medipass/partner-sdk
`}</code></pre>
    <p><strong parentName="p">{`Download via unpkg.com`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js"
      }}>{`https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js`}</a></p>
    <h4 {...{
      "id": "using-the-javascript-module",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#using-the-javascript-module",
        "aria-label": "using the javascript module permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the JavaScript Module`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import medipassSDK from '@medipass/partner-sdk';
// or: const medipassSDK = require('@medipass/partner-sdk');

medipassSDK.setConfig({
  env: 'stg',
  appId: '[insert your App ID here]',
  appVersion: '[insert your App version here]'
});

medipassSDK.renderAuthorizePayment({}, {
  token: '[insert your token here]'
  containerId: 'authorize-payment',
  width: '400px',

  onSuccess: function (transaction) {
    // handle successful submission of transaction
  },
  onError: function (error) {
    // handle errored submission of transaction
  },
  onCancel: function () {
    // handle when create transaction flow has been cancelled
  }
});
`}</code></pre>
    <p>{`then in your HTML:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div id="authorize-payment"></div>
`}</code></pre>
    <p>{`The above will inject the embedded Payment Authority frame into a DOM element of id `}<inlineCode parentName="p">{`authorize-payment`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "using-a-script-tag",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#using-a-script-tag",
        "aria-label": "using a script tag permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using a `}<inlineCode parentName="h4">{`<script>`}</inlineCode>{` tag`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
<head>
  <script src="https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js"></script>
</head>
<body>
  <div id="authorize-payment"></div>
  <script>
    MedipassTransactionSDK.setConfig({
      env: 'stg',
      appId: '[insert your App ID here]',
      appVersion: '[insert App version key here]'
    });

    MedipassTransactionSDK.renderAuthorizePayment({}, {
      token: '[insert your token here]'
      containerId: 'authorize-payment',
      width: '400px',

      onSuccess: data => {
        // handle success
      },
      onError: data => {
        // handle error
      },
      onCancel: () => {
        // handle cancel
      }
    });
  </script>
</body>
</html>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      